import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Link, Box } from "@mui/material";

import appStore from "../../img/AppStore.svg";
import playStore from "../../img/GooglePlay.svg";
import { TERMS_AND_CONDITION, PRIVACY_POLICY_ROUTE } from "../../Auth/Routes";
import {
  mainLinksName,
  mobileAppLinks,
  socialIconsArray,
} from "../../consts/footerConstants";
import { styles } from "./FooterStyles";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Box sx={styles.footer}>
      <Box sx={styles.footerContainer}>
        <Box sx={styles.rootContainer}>
          <Box sx={styles.appIconContainer}>
            <Link
              underline="none"
              href={mobileAppLinks.playStore}
              target="_blank"
            >
              <Typography
                component="img"
                src={playStore}
                alt="Playstore"
                sx={styles.downloadLinksImages}
              />
            </Link>
            <Link
              underline="none"
              href={mobileAppLinks.appStore}
              target="_blank"
            >
              <Typography
                component="img"
                src={appStore}
                alt="AppStore"
                sx={styles.downloadLinksImages}
              />
            </Link>
          </Box>
          <Box sx={styles.copyRightColumnStyles}>
            <Typography
              sx={{
                ...styles.copyRightText,
              }}
            >
              © Care Pulse, LLC
            </Typography>
            <Box sx={styles.divider}></Box>
            <Link underline="none" href={PRIVACY_POLICY_ROUTE} target="_blank">
              <Typography
                sx={{ ...styles.copyRightText, ...styles.mainLinksText }}
              >
                {t(mainLinksName.privacyPolicy)}
              </Typography>
            </Link>
            <Box sx={styles.divider}></Box>
            <Link underline="none" href={TERMS_AND_CONDITION} target="_blank">
              <Typography
                sx={{ ...styles.copyRightText, ...styles.mainLinksText }}
              >
                {t(mainLinksName.termsOfService)}
              </Typography>
            </Link>
          </Box>
          <Box sx={styles.socialIconsBox}>
            {socialIconsArray.map((socialIcon, idx) => (
              <Link
                key={idx}
                underline="none"
                href={socialIcon.link}
                target="_blank"
                aria-label={socialIcon.name}
              >
                {socialIcon.svg ? (
                  <Typography
                    component="img"
                    src={socialIcon.svg}
                    alt="Playstore"
                    width={22}
                    height={22}
                  />
                ) : (
                  <socialIcon.icon
                    sx={{
                      ...styles.socialIcons,
                      ...(socialIcon.name === "Twitter"
                        ? styles.twitterIcon
                        : {}),
                    }}
                  />
                )}
              </Link>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
